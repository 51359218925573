<template>
  <div class="container">

    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <!-- <div class="dohand">
        <div class="preset">预设</div>
        <div class="amount">金额<input type="text" /></div>
        <div class="btn none">确认</div>
        <div class="btn yes">重置</div>
        <div class="btn2 a">结果走势</div>
   
      </div> -->
      <div class="player listmain type-sxg lgx5 game-lgx5 game-sxg-lm">
        <div class="data clst">
          <h3>{{rowsData[0].title}}</h3>
          <ul class="custom_ul">
            <li v-for="ele in rowsData[0].arr" :key="ele.ResultID" :class="ele.selected?'selected':''">
              <ol style="width:44px" class="td_name">{{ele.label}}</ol>
              <ol class="td_rate">{{ele.Odds}}</ol>
              <ol class="td_cash lianTip">
                <input type="number" v-model="ele.money" @click.stop="showOrHideCheck($event, ele)">
                <div class="quick-check" v-if="ele.b">
                  <ul>
                    <li style="width:40px" v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(ele, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(ele)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>

          </ul>
        </div>
        <!-- 无球 -->
        <template v-for="(item,index) in rowsData">
          <div class="data" :class="'cZ'+index" v-if="index>0" :key="item.title">
            <h3>{{item.title}}</h3>
            <ul>
              <li v-for="ele in item.arr" :key="ele.ResultID" :class="ele.selected?'selected':''" @click="setCurrentSelected(ele)">
                <ol style="width:44px" class="td_name">{{ele.label}}</ol>
                <ol class="td_rate">{{ele.Odds}}</ol>
                <ol class="td_cash">
                  <input type="number" @click.stop="showOrHideCheck($event, ele)" v-model="ele.money">
                  <div class="quick-check" v-if="ele.b">
                    <ul>
                      <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(ele, qs.num)">
                        下注{{ qs.num }}元
                      </li>

                      <li @click="closeCheck(ele)">关闭</li>
                    </ul>
                  </div>
                </ol>
              </li>

            </ul>
          </div>
        </template>
        <!-- --- -->
      </div>
      <!-- 预设 -->
      <yushe />
      <!--表格  -->
      <div class="roadmap">
        <table class="thlottab">
          <tbody>
            <tr>
              <td :class="qiuIndex==1?'selected':''" @click="changeQiu(1)">第一球</td>
              <td :class="qiuIndex==2?'selected':''" @click="changeQiu(2)">第二球</td>
              <td :class="qiuIndex==3?'selected':''" @click="changeQiu(3)">第三球</td>
              <td :class="qiuIndex==4?'selected':''" @click="changeQiu(4)">第四球</td>
              <td :class="qiuIndex==5?'selected':''" @click="changeQiu(5)">第五球</td>
            </tr>
          </tbody>
        </table>
        <table class="ltbinfo">
          <tbody>
            <tr>
              <th :class="ltIndex==1?'selected':''" @click="changeLutuData(1)">单双</th>
              <th :class="ltIndex==2?'selected':''" @click="changeLutuData(2)">大小</th>
              <th :class="ltIndex==3?'selected':''" @click="changeLutuData(3)">龙虎</th>
              <th :class="ltIndex==4?'selected':''" @click="changeLutuData(4)">总和单双</th>
              <th :class="ltIndex==5?'selected':''" @click="changeLutuData(5)">总和大小</th>
            </tr>
          </tbody>
        </table>

        <table colspan="0" id="lt_ds" cellspan="0" class="lttab">
          <tbody>
            <tr v-for="(item,index) in ltArr" :key="index">
              <td v-for="(ele,i) in item" :key="ele+i">
                <span :class="fontColor[ele]">{{ele}}</span>

              </td>

            </tr>

          </tbody>
        </table>

        <!-- --- -->
      </div>

    </div>
    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long" v-if="changelongActive=='1'">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist" v-if="kaiActive=='1'">
            <li v-for="item in rclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="red">{{item.num}}期</b>
            </li>
          </ul>
          <ul class="longlist" v-if="kaiActive=='2'">
            <li v-for="item in lclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="blue">{{item.num}}期</b>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import yanzheng from "../yanzheng.vue";
import mixins from "../mixins/mainMixins";
export default {
  name: "",
  props: [""],
  mixins: [mixins],
  data() {
    return {
      groupnames:'lm',
      ltArr: [],
      rowsData: [
        { title: "总和、龙虎", arr: [] },
        { title: "第一球", arr: [] },
        { title: "第二球", arr: [] },
        { title: "第三球", arr: [] },
        { title: "第四球", arr: [] },
        { title: "第五球", arr: [] },
      ],
      labelArr1: [
        "总和大",
        "总和小",
        "总和单",
        "总和双",
        "总尾大",
        "总尾小",
        "龙",
        "虎",
      ],
      labelArr2: ["单", "双", "大", "小"],
      lutu1: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      },

      lutu2: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      },
      // 第一球录图
      lutu3: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      },
      // 第一球录图
      lutu4: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      },
      // 第一球录图
      lutu5: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      },
    };
  },
  components: {
    yushe,
    topTimer,
    yanzheng,
  },
  watch: {
    // 判断是否可以点击确定提交按钮
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: ele.label,
                  Odds: ele.Odds,
                  title: item.title == "总和、龙虎" ? "" : item.title,
                  id: ele.ResultID,
                  money: Number(ele.money),
                };
                arr.push(obj);
              }
            });
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        // 总和、龙虎
        let arr1 = this.filterData(resAArr, 1021, 1028, this.labelArr1);
        this.rowsData[0].arr = arr1;
        // 第一球
        let arr2 = this.ddFilterData(
          resAArr,
          [1031, 1032, 1029, 1030],
          this.labelArr2
        );
        this.rowsData[1].arr = arr2;
        // 第二球
        let arr3 = this.ddFilterData(
          resAArr,
          [1035, 1036, 1033, 1034],
          this.labelArr2
        );
        this.rowsData[2].arr = arr3;
        // 第三球
        let arr4 = this.ddFilterData(
          resAArr,
          [1039, 1040, 1037, 1038],
          this.labelArr2
        );
        this.rowsData[3].arr = arr4;
        // 第四球
        let arr5 = this.ddFilterData(
          resAArr,
          [1043, 1044, 1041, 1042],
          this.labelArr2
        );
        this.rowsData[4].arr = arr5;
        // 第五球
        let arr6 = this.ddFilterData(
          resAArr,
          [1047, 1048, 1045, 1046],
          this.labelArr2
        );
        this.rowsData[5].arr = arr6;

        this.$forceUpdate();
      },
    },
  },
  created() {},

  mounted() {},

  methods: {
    getKJHis() {
      this.lutu1 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      };
      this.lutu2 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      };
      this.lutu3 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      };
      this.lutu4 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      };
      this.lutu5 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      };
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        pagenum: 1,
        pagecount: 200,
        roomeng: this.currentGame.roomeng,
        begindate: this.splitDate(this.getBeforeDate(0)),
        enddate: this.splitDate(this.getBeforeDate(0)),
      };
      this.$http.post("getlotteryhis", obj).then((res) => {
        if (res.Status) {
          let resObj = JSON.parse(res.Msg);
          if (resObj.data) {
            // 第一球----------
            let arr1_ds = [[]],
              arr1_dx = [[]],
              // 第二球
              arr2_ds = [[]],
              arr2_dx = [[]],
              // 第三球
              arr3_ds = [[]],
              arr3_dx = [[]],
              // 第四球
              arr4_ds = [[]],
              arr4_dx = [[]],
              // 第五球
              arr5_ds = [[]],
              arr5_dx = [[]],
              // ------------------
              arr_zh = [[]],
              arr_lh = [[]],
              arr_zhds = [[]],
              arr_zhdx = [[]];
            // --------------
            let list = resObj.data;
            list.reverse()
            list.forEach((element, index) => {
              element.codeArr = element.Opencode.split(",");
              // -第一球------------------------------------------------
              //  单双
              let q1 = element.codeArr[0];
              let ds1 = "";
              if (q1 == 11) {
                ds1 = "和";
              } else {
                ds1 = q1 % 2 == 0 ? "双" : "单";
              }

              this.initlutuData(arr1_ds, ds1);
              // 大小
              let dx1 = "";
              if (q1 == 11) {
                dx1 = "和";
              } else {
                dx1 = q1 >= 5 ? "大" : "小";
              }

              this.initlutuData(arr1_dx, dx1);
              //  第二球--------------- ---------------------------------
              let q2 = element.codeArr[1];
              let ds2 = "";
              if (q2 == 11) {
                ds2 = "和";
              } else {
                ds2 = q1 % 2 == 0 ? "双" : "单";
              }

              this.initlutuData(arr2_ds, ds2);
              // 大小
              let dx2 = "";
              if (q2 == 11) {
                dx2 = "和";
              } else {
                dx2 = q2 >= 5 ? "大" : "小";
              }
              this.initlutuData(arr2_dx, dx2);
              //  第三球--------------- ---------------------------------
              let q3 = element.codeArr[2];
              let ds3 = "";
              if (q3 == 11) {
                ds3 = "和";
              } else {
                ds3 = q3 % 2 == 0 ? "双" : "单";
              }

              this.initlutuData(arr3_ds, ds3);
              // 大小
              let dx3 = "";
              if (q3 == 11) {
                dx3 = "和";
              } else {
                dx3 = q3 >= 5 ? "大" : "小";
              }

              this.initlutuData(arr3_dx, dx3);
              //  第四球--------------- ---------------------------------
              let q4 = element.codeArr[3];
              let ds4 = "";
              if (q4 == 11) {
                ds4 = "和";
              } else {
                ds4 = q4 % 2 == 0 ? "双" : "单";
              }

              this.initlutuData(arr4_ds, ds4);
              // 大小
              let dx4 = "";
              if (q4 == 11) {
                dx4 = "和";
              } else {
                dx4 = q4 >= 5 ? "大" : "小";
              }

              this.initlutuData(arr4_dx, dx4);
              //  第五球--------------- ---------------------------------
              let q5 = element.codeArr[4];
              let ds5 = "";
              if (q5 == 11) {
                ds5 = "和";
              } else {
                ds5 = q5 % 2 == 0 ? "双" : "单";
              }

              this.initlutuData(arr5_ds, ds5);
              // 大小
              let dx5 = "";
              if (q5 == 11) {
                dx5 = "和";
              } else {
                dx5 = q5 >= 5 ? "大" : "小";
              }

              this.initlutuData(arr5_dx, dx5);
              // 龙虎
              let lh =
                Number(element.codeArr[0]) - Number(element.codeArr[4]) > 0
                  ? "龙"
                  : "虎";
              this.initlutuData(arr_lh, lh);

              // 总和
              let sum = 0;
              element.codeArr.forEach((num) => {
                sum += Number(num);
              });
              // this.initlutuData(arr_zh, sum);
              // 总和单双大小
              let sumds = sum % 2 == 0 ? "双" : "单";
              let sumdx = "";
              if (sum == 30) {
                sumdx = "和";
              } else if (sum > 30) {
                sumdx = "大";
              } else {
                sumdx = "小";
              }

              this.initlutuData(arr_zhds, sumds);
              this.initlutuData(arr_zhdx, sumdx);
              // ----------------------------------------------------------
            });
            // 第一球 单双 大小 总和
            this.buquanArr(arr1_ds, this.lutu1.arr1);
            this.buquanArr(arr1_dx, this.lutu1.arr2);
            // 第二球 单双 大小 总和
            this.buquanArr(arr2_ds, this.lutu2.arr1);
            this.buquanArr(arr2_dx, this.lutu2.arr2);
            // 第三球 单双 大小 总和
            this.buquanArr(arr3_ds, this.lutu3.arr1);
            this.buquanArr(arr3_dx, this.lutu3.arr2);
            // 第四球 单双 大小 总和
            this.buquanArr(arr4_ds, this.lutu4.arr1);
            this.buquanArr(arr4_dx, this.lutu4.arr2);
            // 第五球 单双 大小 总和
            this.buquanArr(arr5_ds, this.lutu5.arr1);
            this.buquanArr(arr5_dx, this.lutu5.arr2);
            // 龙虎---------------------------------
            this.buquanArr(arr_lh, this.lutu1.arr3);
            this.buquanArr(arr_lh, this.lutu2.arr3);
            this.buquanArr(arr_lh, this.lutu3.arr3);
            this.buquanArr(arr_lh, this.lutu4.arr3);
            this.buquanArr(arr_lh, this.lutu5.arr3);

            this.buquanArr(arr_zhds, this.lutu1.arr4);
            this.buquanArr(arr_zhds, this.lutu2.arr4);
            this.buquanArr(arr_zhds, this.lutu3.arr4);
            this.buquanArr(arr_zhds, this.lutu4.arr4);
            this.buquanArr(arr_zhds, this.lutu5.arr4);

            this.buquanArr(arr_zhdx, this.lutu1.arr5);
            this.buquanArr(arr_zhdx, this.lutu2.arr5);
            this.buquanArr(arr_zhdx, this.lutu3.arr5);
            this.buquanArr(arr_zhdx, this.lutu4.arr5);
            this.buquanArr(arr_zhdx, this.lutu5.arr5);
            this.ltArr = this["lutu" + this.qiuIndex]["arr" + this.ltIndex];
            this.$forceUpdate();
          } else {
            this.ltArr = [];
          }
        } else {
          this.ltArr = [];
        }
      });
    },
  },
};
</script>
<style  scoped>
.listmain > .data.clst > ul.custom_ul {
  width: 100%;
  height: 52px;
  display: flex;

  flex-flow: column wrap !important;
}
.listmain div.clst ul li {
  width: 25%;
  height: 26px;
}
</style>